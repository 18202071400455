var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n  <div class=\"grid-sm-1 grid-md-1-2\">\n    <nav>\n      <ul>\n        <li>\n            <a data-history-navigate href=\"/about/volunteers\">How can "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1), depth0))
    + " help me</a>\n        </li>\n        <li>\n          <a data-history-navigate href=\"javascript:void(0);\" id=\"aUserAgreementFooter\">User Agreement Document</a>\n        </li>\n        <li>\n          <a data-bypass href=\"/privacy\">Privacy Practices & Cookie Settings</a>\n        </li>\n      </ul>\n    </nav>\n  </div>\n  <section class=\"grid-sm-1 grid-md-1-2\">\n      <div class=\"contact-card\">\n        <h1 class=\"gamma\">For technical support, contact:</h1>\n        <address>\n            <span class=\"tel\"><span class=\"fa fa-phone fa-fw\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">Telephone:</span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportPhoneNumber") : stack1), depth0))
    + "</span>\n            <span class=\"email\"><span class=\"fa fa-envelope fa-fw\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">E-mail:</span><a data-history-navigate href=\"mailto:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1), depth0))
    + "</a></span>\n        </address>\n      </div>\n  </section>\n</div>\n\n<div class=\"grid-row logos\">\n    <div class=\"grid-sm-1 grid-md-1-2\">\n        <a data-history-navigate href=\"http://www.michr.umich.edu\" class=\"department-logo\"><span class=\"show-for-sr\">MICHR logo</span></a>\n    </div>\n    <div class=\"grid-sm-1 grid-md-1-2\">\n        <a data-history-navigate href=\"https://yourhealthresearch.org\" class=\"powered-by\"></a>\n    </div>\n</div>\n<div class=\"grid-row\">\n  <section class=\"grid-1 copyright\">\n    <div>© "
    + alias2(((helper = (helper = lookupProperty(helpers,"currentYear") || (depth0 != null ? lookupProperty(depth0,"currentYear") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"currentYear","hash":{},"data":data,"loc":{"start":{"line":38,"column":11},"end":{"line":38,"column":26}}}) : helper)))
    + " Regents of "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"institutionName") : stack1), depth0))
    + ".</div>\n    <div>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1), depth0))
    + "\n        is approved by IRBMED at "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"institutionName") : stack1), depth0))
    + "\n        (IRB number: HUM00004760).</div>\n  </section>\n</div>\n\n";
},"useData":true});