var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"grid-row canned-queries-list\">\n    <div class=\"grid-1\">\n        <h1 class=\"special-header-with-top-border center-container\"><em>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":110}}}))
    + "</em></h1>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-15\">\n        <a data-history-navigate href=\"/study-results?topics=285138\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.covidStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":66}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-1\">\n        <a data-history-navigate href=\"/study-results?gender=272001&onlyHealthy=395000\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForMen",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":23},"end":{"line":12,"column":67}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-2\">\n        <a data-history-navigate href=\"/study-results?gender=272000&onlyHealthy=395000\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForWomen",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":23},"end":{"line":17,"column":69}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-3\">\n        <a data-history-navigate href=\"/study-results?locations=420002\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesFromHome",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":23},"end":{"line":22,"column":69}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-4\">\n        <a data-history-navigate href=\"/study-results?topics=285006,285028,285035,285042,285052,285055,285063,285082,285125,285129,285152,285157,285179,285188,285257,285358,285360,285379,285380,285383,285413,285414,285446,285405,285478,285479,285496,285498,285511,285512,285517,285531\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForMentalHealth",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":23},"end":{"line":27,"column":76}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-5\">\n        <a data-history-navigate href=\"/study-results?topics=285073,285156,285153,285154,285155,285534,285290,285533,285574\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForDentalHealth",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":23},"end":{"line":32,"column":76}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-6\">\n        <a data-history-navigate href=\"/study-results?topics=285008,285011,285065,285113,285136,285137,285140,285147,285161,285205,285206,285207,285238,285228,285289,285294,285288,285307,285323,285312,285345,285374,285378,285381,285384,285428,285451,285458,285459,285455,285490,285504,285528,285519,285570\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForEye",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":37,"column":23},"end":{"line":37,"column":67}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-7\">\n        <a data-history-navigate href=\"/study-results?topics=285395\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.obesityStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":23},"end":{"line":42,"column":68}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-8\">\n        <a data-history-navigate href=\"/study-results?topics=285161,285162,285538,285163,285164,285165,285438,285423,285436,285539\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.diabeticStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":23},"end":{"line":47,"column":69}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-9\">\n        <a data-history-navigate href=\"/study-results?topics=285001,285000,285002,285018,285053,285046,285080,285100,285148,285038,285525,285043,285044,285016,285017,285343,285344,285098,285099,285133,285084,285249,285261,285341,285462,285316,285407,285406,285401,285473,285554,285558,285256,285398,285091,285097,285286,285045,285185,285314,285347,285037\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.cardiovascularStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":52,"column":23},"end":{"line":52,"column":75}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-10\">\n        <a data-history-navigate href=\"/study-results?topics=285474\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.safetyStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":57,"column":23},"end":{"line":57,"column":67}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-11\">\n        <a data-history-navigate href=\"/study-results?topics=285072\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.breastCancerStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":62,"column":23},"end":{"line":62,"column":73}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-12\">\n        <a data-history-navigate href=\"/study-results?topics=285334\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.lungCancerStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":23},"end":{"line":67,"column":71}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-13\">\n        <a data-history-navigate href=\"/study-results?topics=285444\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.prostateCancerStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":72,"column":23},"end":{"line":72,"column":75}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-14\">\n        <a data-history-navigate href=\"/study-results?topics=285127,285128\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.colonCancerStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":77,"column":23},"end":{"line":77,"column":72}}}))
    + "</span></div>\n        </a>\n    </div>\n\n\n    <div class=\"grid-1 text-center delta\">\n        <a data-history-navigate href=\"/study-results\">\n            <div class=\"white-background border\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.allStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":84,"column":49},"end":{"line":84,"column":90}}}))
    + "</div>\n        </a>\n    </div>\n</section>\n";
},"useData":true});