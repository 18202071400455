require('cookieconsent');
var i18n = require('../../../shared-scripts/app-util/i18n');
var cookieName = 'cookie_consent';
var allowValue = i18n.t('cookieConsent:banner.button.allow.label');
var denyValue = i18n.t('cookieConsent:banner.button.deny.label');

var loadCookieConsent = function () {
	window.cookieconsent.initialise({
		palette: {
		},
		cookie: {
			name: cookieName,
		},
		type: 'opt-in',
		content: {
			href: '/privacy',
			target: '',
			policy: '',
			message: i18n.t('cookieConsent:banner.message'),
			link: i18n.t('cookieConsent:banner.link.label'),
		},
		onInitialise: function () {
			updateCookie.call(this);
		},
		onStatusChange: function () {
			updateCookie.call(this);
		}
	});
};

function updateCookie() {
	var didConsent = this.hasConsented();
	if (didConsent) {
		enableCookies();
		checkAllowCookiesCheckbox();
	} else {
		deleteCookies();
		unCheckAllowCookiesCheckbox();
	}
}

function checkAllowCookiesCheckbox() {
	$('#checkboxCookieConsent').prop('checked', true);
}

function unCheckAllowCookiesCheckbox() {
	$('#checkboxCookieConsent').prop('checked', false);
}

function enableCookies() {
	var tagManagerHead = typeof TAG_HEAD === 'undefined' ? '' : TAG_HEAD;
	var tagManagerBody = typeof TAG_BODY === 'undefined' ? '' : TAG_BODY;

	if (tagManagerHead && tagManagerBody) {
		writeNewTag('head', 'script', 'scriptGTM', tagManagerHead);
		writeNewTag('body', 'noscript', 'noscriptGTM', tagManagerBody);
	}
}

function writeNewTag(targetTag, newTag, id, content) {
	if (!document.getElementById(id)) {
		var target = document.querySelector(targetTag);
		var newElement = document.createElement(newTag);
		newElement.setAttribute('id', id);
		var inlineText = document.createTextNode(content);
		newElement.appendChild(inlineText);
		target.appendChild(newElement);
	}
}

function deleteCookies() {
	var domains = [''];
	var domainParts = window.location.hostname.split('.');
	for (var i = 0; i < domainParts.length; i++) {
		domains.push(domainParts.slice(i).join('.'));
	}

	var cookieNames = document.cookie.split(';')
		.map(function (c) {
			return c.trim();
		})
		.map(function (c) {
			return c.split('=')[0];
		})
		.filter(function (n) {
			return n !== cookieName;
		});

	cookieNames.forEach(function(name) {
		if(name!=='lang'){
			domains.forEach(function (domain) {
				window.cookieconsent.utils.setCookie(name, '', -1, domain);
			});
		}
	});
}

module.exports = {
	loadCookieConsent: loadCookieConsent,
	enableCookies: enableCookies,
	deleteCookies: deleteCookies,
	cookieName: cookieName,
	allowValue: allowValue,
	denyValue: denyValue
};
